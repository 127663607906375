
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as error2FY1UUF_jDVLYspjSL7OZ7abMnwe_0GGERpk3aXKTaUMeta } from "/opt/build/repo/website/careers/pages/error.vue?macro=true";
import { default as index3DsNPy_45xtn3g_0mexEJvo7_451QtkaTF_K8wtPSLfIMp8Meta } from "/opt/build/repo/website/careers/pages/index.vue?macro=true";
import { default as indexZfZDyRZioGl_dkS5xW1p17ZvcItKsCx4zUevu3eXljgMeta } from "/opt/build/repo/website/careers/pages/faqs/index.vue?macro=true";
import { default as indexmMyO5E8SNOGjvMkmxHA_45ENftPtzIhYq_45r4mp2rJJTu4Meta } from "/opt/build/repo/website/careers/pages/formula/index.vue?macro=true";
import { default as indexvErUM6_2iQ19jJqDt49FvTw_45d724CeSWMLBTOxdWX1UMeta } from "/opt/build/repo/website/careers/pages/locations/index.vue?macro=true";
import { default as indexyCxIYUSlVtXfht9Po3yETh4Co1y9lAqzkXjTdhjYSV8Meta } from "/opt/build/repo/website/careers/pages/vacancies/index.vue?macro=true";
import { default as _91slug_93hh9cx8CMNJFrczYP3uZ23dySWTHFUUoxTFBAxHWWVe0Meta } from "/opt/build/repo/website/careers/pages/locations/[slug].vue?macro=true";
import { default as _91slug_93AqdCS6ghn6VqH_45jP3u7tJ8b4bXPHfpyGY0wZIX9P8cUMeta } from "/opt/build/repo/website/careers/pages/vacancies/[slug].vue?macro=true";
import { default as indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta } from "/opt/build/repo/website/careers/pages/career-types/index.vue?macro=true";
import { default as _91slug_93_45CY7xMK26_45QFsh1qY3Qed5FYu49wPMBB_X2bvxpRvXcMeta } from "/opt/build/repo/website/careers/pages/career-types/[slug].vue?macro=true";
import { default as indexp_Qh6OeQnFZtMjslgz7CV5rTF1i_45WZEtZWac_45vFWYrQMeta } from "/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue?macro=true";
import { default as component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "error___en",
    path: "/error",
    component: () => import("/opt/build/repo/website/careers/pages/error.vue")
  },
  {
    name: "error___pt_BR",
    path: "/pt_BR/error",
    component: () => import("/opt/build/repo/website/careers/pages/error.vue")
  },
  {
    name: "error___da_DK",
    path: "/da_DK/error",
    component: () => import("/opt/build/repo/website/careers/pages/error.vue")
  },
  {
    name: "error___de_DE",
    path: "/de_DE/error",
    component: () => import("/opt/build/repo/website/careers/pages/error.vue")
  },
  {
    name: "error___es_ES",
    path: "/es_ES/error",
    component: () => import("/opt/build/repo/website/careers/pages/error.vue")
  },
  {
    name: "error___fr_FR",
    path: "/fr_FR/error",
    component: () => import("/opt/build/repo/website/careers/pages/error.vue")
  },
  {
    name: "error___it_IT",
    path: "/it_IT/error",
    component: () => import("/opt/build/repo/website/careers/pages/error.vue")
  },
  {
    name: "error___ja_JP",
    path: "/ja_JP/error",
    component: () => import("/opt/build/repo/website/careers/pages/error.vue")
  },
  {
    name: "error___no_NO",
    path: "/no_NO/error",
    component: () => import("/opt/build/repo/website/careers/pages/error.vue")
  },
  {
    name: "error___sv_SE",
    path: "/sv_SE/error",
    component: () => import("/opt/build/repo/website/careers/pages/error.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___pt_BR",
    path: "/pt_BR",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___da_DK",
    path: "/da_DK",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___de_DE",
    path: "/de_DE",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___es_ES",
    path: "/es_ES",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___fr_FR",
    path: "/fr_FR",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___it_IT",
    path: "/it_IT",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___ja_JP",
    path: "/ja_JP",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___no_NO",
    path: "/no_NO",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "index___sv_SE",
    path: "/sv_SE",
    component: () => import("/opt/build/repo/website/careers/pages/index.vue")
  },
  {
    name: "faqs___en",
    path: "/faqs",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___pt_BR",
    path: "/pt_BR/perguntas-frequentes",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___da_DK",
    path: "/da_DK/ofte-stillede-sporgsmal",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___de_DE",
    path: "/de_DE/faq",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___es_ES",
    path: "/es_ES/preguntas-frecuentes",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___fr_FR",
    path: "/fr_FR/faq",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___it_IT",
    path: "/it_IT/faq",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___ja_JP",
    path: "/ja_JP/faqs",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___no_NO",
    path: "/no_NO/ofte-stilte-sporsmal",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "faqs___sv_SE",
    path: "/sv_SE/vanliga-fragor",
    component: () => import("/opt/build/repo/website/careers/pages/faqs/index.vue")
  },
  {
    name: "formula___en",
    path: "/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___pt_BR",
    path: "/pt_BR/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___da_DK",
    path: "/da_DK/formel",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___de_DE",
    path: "/de_DE/die-adyen-formel",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___es_ES",
    path: "/es_ES/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___fr_FR",
    path: "/fr_FR/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___it_IT",
    path: "/it_IT/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___ja_JP",
    path: "/ja_JP/formula",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___no_NO",
    path: "/no_NO/formel",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "formula___sv_SE",
    path: "/sv_SE/formeln",
    component: () => import("/opt/build/repo/website/careers/pages/formula/index.vue")
  },
  {
    name: "locations___en",
    path: "/locations",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___pt_BR",
    path: "/pt_BR/onde-estamos",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___da_DK",
    path: "/da_DK/placeringer",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___de_DE",
    path: "/de_DE/standorte",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___es_ES",
    path: "/es_ES/ubicaciones",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___fr_FR",
    path: "/fr_FR/bureaux",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___it_IT",
    path: "/it_IT/localita",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___ja_JP",
    path: "/ja_JP/locations",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___no_NO",
    path: "/no_NO/steder",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "locations___sv_SE",
    path: "/sv_SE/platser",
    component: () => import("/opt/build/repo/website/careers/pages/locations/index.vue")
  },
  {
    name: "vacancies___en",
    path: "/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___pt_BR",
    path: "/pt_BR/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___da_DK",
    path: "/da_DK/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___de_DE",
    path: "/de_DE/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___es_ES",
    path: "/es_ES/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___fr_FR",
    path: "/fr_FR/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___it_IT",
    path: "/it_IT/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___ja_JP",
    path: "/ja_JP/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___no_NO",
    path: "/no_NO/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___sv_SE",
    path: "/sv_SE/vacancies",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/index.vue")
  },
  {
    name: "locations-slug___en",
    path: "/locations/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___pt_BR",
    path: "/pt_BR/onde-estamos/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___da_DK",
    path: "/da_DK/placeringer/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___de_DE",
    path: "/de_DE/standorte/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___es_ES",
    path: "/es_ES/ubicaciones/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___fr_FR",
    path: "/fr_FR/bureaux/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___it_IT",
    path: "/it_IT/localita/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___ja_JP",
    path: "/ja_JP/locations/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___no_NO",
    path: "/no_NO/steder/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "locations-slug___sv_SE",
    path: "/sv_SE/platser/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/locations/[slug].vue")
  },
  {
    name: "vacancies-slug___en",
    path: "/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___pt_BR",
    path: "/pt_BR/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___da_DK",
    path: "/da_DK/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___de_DE",
    path: "/de_DE/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___es_ES",
    path: "/es_ES/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___fr_FR",
    path: "/fr_FR/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___it_IT",
    path: "/it_IT/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___ja_JP",
    path: "/ja_JP/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___no_NO",
    path: "/no_NO/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "vacancies-slug___sv_SE",
    path: "/sv_SE/vacancies/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/vacancies/[slug].vue")
  },
  {
    name: "career-types___en",
    path: "/career-types",
    meta: indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___pt_BR",
    path: "/pt_BR/tipos-de-carreira",
    meta: indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___da_DK",
    path: "/da_DK/karrieretyper",
    meta: indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___de_DE",
    path: "/de_DE/verschiedene-berufe",
    meta: indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___es_ES",
    path: "/es_ES/tipos-de-oportunidades-laborales",
    meta: indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___fr_FR",
    path: "/fr_FR/domaines-de-carriere",
    meta: indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___it_IT",
    path: "/it_IT/tipi-di-opportunita-professionali",
    meta: indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___ja_JP",
    path: "/ja_JP/career-types",
    meta: indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___no_NO",
    path: "/no_NO/karrieretyper",
    meta: indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types___sv_SE",
    path: "/sv_SE/karriartyper",
    meta: indexvt_5m6rKFlskDP4dxfWDH53g08paymLGjv9IpP1FxmIMeta || {},
    component: () => import("/opt/build/repo/website/careers/pages/career-types/index.vue")
  },
  {
    name: "career-types-slug___en",
    path: "/career-types/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___pt_BR",
    path: "/pt_BR/tipos-de-carreira/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___da_DK",
    path: "/da_DK/karrieretyper/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___de_DE",
    path: "/de_DE/verschiedene-berufe/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___es_ES",
    path: "/es_ES/tipos-de-oportunidades-laborales/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___fr_FR",
    path: "/fr_FR/domaines-de-carriere/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___it_IT",
    path: "/it_IT/tipi-di-opportunita-professionali/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___ja_JP",
    path: "/ja_JP/career-types/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___no_NO",
    path: "/no_NO/karrieretyper/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "career-types-slug___sv_SE",
    path: "/sv_SE/karriartyper/:slug()",
    component: () => import("/opt/build/repo/website/careers/pages/career-types/[slug].vue")
  },
  {
    name: "diversity-equity-inclusion___en",
    path: "/diversity-equity-inclusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___pt_BR",
    path: "/pt_BR/diversidade-equidade-inclusao",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___da_DK",
    path: "/da_DK/diversitet-lighed-inklusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___de_DE",
    path: "/de_DE/diversity-equity-inclusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___es_ES",
    path: "/es_ES/diversidad-equidad-inclusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___fr_FR",
    path: "/fr_FR/diversite-equite-inclusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___it_IT",
    path: "/it_IT/diversita-equita-inclusione",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___ja_JP",
    path: "/ja_JP/diversity-equity-inclusion",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___no_NO",
    path: "/no_NO/mangfold-likeverd-inkludering",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: "diversity-equity-inclusion___sv_SE",
    path: "/sv_SE/mangfald-jamlikhet-inkludering",
    component: () => import("/opt/build/repo/website/careers/pages/diversity-equity-inclusion/index.vue")
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/how-we-hire",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/pt_BR/how-we-hire",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/da_DK/how-we-hire",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/de_DE/how-we-hire",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/es_ES/how-we-hire",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/fr_FR/how-we-hire",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/it_IT/how-we-hire",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/ja_JP/how-we-hire",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/no_NO/how-we-hire",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/sv_SE/how-we-hire",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/pt_BR/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/da_DK/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/de_DE/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/es_ES/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/fr_FR/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/it_IT/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/ja_JP/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/no_NO/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/sv_SE/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  }
]