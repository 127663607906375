
// @ts-nocheck


export const localeCodes =  [
  "en",
  "pt_BR",
  "da_DK",
  "de_DE",
  "es_ES",
  "fr_FR",
  "it_IT",
  "ja_JP",
  "no_NO",
  "sv_SE"
]

export const localeLoaders = {
  en: [],
  pt_BR: [],
  da_DK: [],
  de_DE: [],
  es_ES: [],
  fr_FR: [],
  it_IT: [],
  ja_JP: [],
  no_NO: [],
  sv_SE: []
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/5df9344a" /* webpackChunkName: "config_i18n_46config_46ts_5df9344a" */)
]

export const nuxtI18nOptions = {
  restructureDir: false,
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: false
  },
  compilation: {
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      name: "Global",
      language: "en",
      files: []
    },
    {
      code: "pt_BR",
      name: "Brasil",
      language: "pt-BR",
      files: []
    },
    {
      code: "da_DK",
      name: "Danmark",
      language: "da-DK",
      files: []
    },
    {
      code: "de_DE",
      name: "Deutschland",
      language: "de-DE",
      files: []
    },
    {
      code: "es_ES",
      name: "España",
      language: "es-ES",
      files: []
    },
    {
      code: "fr_FR",
      name: "France",
      language: "fr-FR",
      files: []
    },
    {
      code: "it_IT",
      name: "Italia",
      language: "it-IT",
      files: []
    },
    {
      code: "ja_JP",
      name: "日本",
      language: "ja-JP",
      files: []
    },
    {
      code: "no_NO",
      name: "Norge",
      language: "no-NO",
      files: []
    },
    {
      code: "sv_SE",
      name: "Sverige",
      language: "sv-SE",
      files: []
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://careers.adyen.com",
  customRoutes: "config",
  pages: {
    "career-types/[slug]": {
      en: "/career-types/[slug]",
      "pt-BR": "/tipos-de-carreira/[slug]",
      pt_BR: "/tipos-de-carreira/[slug]",
      "da-DK": "/karrieretyper/[slug]",
      da_DK: "/karrieretyper/[slug]",
      "de-DE": "/verschiedene-berufe/[slug]",
      de_DE: "/verschiedene-berufe/[slug]",
      "es-ES": "/tipos-de-oportunidades-laborales/[slug]",
      es_ES: "/tipos-de-oportunidades-laborales/[slug]",
      "fr-FR": "/domaines-de-carriere/[slug]",
      fr_FR: "/domaines-de-carriere/[slug]",
      "it-IT": "/tipi-di-opportunita-professionali/[slug]",
      it_IT: "/tipi-di-opportunita-professionali/[slug]",
      "ja-JP": "/career-types/[slug]",
      ja_JP: "/career-types/[slug]",
      "no-NO": "/karrieretyper/[slug]",
      no_NO: "/karrieretyper/[slug]",
      "sv-SE": "/karriartyper/[slug]",
      sv_SE: "/karriartyper/[slug]"
    },
    "career-types/index": {
      en: "/career-types",
      "pt-BR": "/tipos-de-carreira",
      pt_BR: "/tipos-de-carreira",
      "da-DK": "/karrieretyper",
      da_DK: "/karrieretyper",
      "de-DE": "/verschiedene-berufe",
      de_DE: "/verschiedene-berufe",
      "es-ES": "/tipos-de-oportunidades-laborales",
      es_ES: "/tipos-de-oportunidades-laborales",
      "fr-FR": "/domaines-de-carriere",
      fr_FR: "/domaines-de-carriere",
      "it-IT": "/tipi-di-opportunita-professionali",
      it_IT: "/tipi-di-opportunita-professionali",
      "ja-JP": "/career-types",
      ja_JP: "/career-types",
      "no-NO": "/karrieretyper",
      no_NO: "/karrieretyper",
      "sv-SE": "/karriartyper",
      sv_SE: "/karriartyper"
    },
    "diversity-equity-inclusion/index": {
      en: "/diversity-equity-inclusion",
      "pt-BR": "/diversidade-equidade-inclusao",
      pt_BR: "/diversidade-equidade-inclusao",
      "da-DK": "/diversitet-lighed-inklusion",
      da_DK: "/diversitet-lighed-inklusion",
      "de-DE": "/diversity-equity-inclusion",
      de_DE: "/diversity-equity-inclusion",
      "es-ES": "/diversidad-equidad-inclusion",
      es_ES: "/diversidad-equidad-inclusion",
      "fr-FR": "/diversite-equite-inclusion",
      fr_FR: "/diversite-equite-inclusion",
      "it-IT": "/diversita-equita-inclusione",
      it_IT: "/diversita-equita-inclusione",
      "ja-JP": "/diversity-equity-inclusion",
      ja_JP: "/diversity-equity-inclusion",
      "no-NO": "/mangfold-likeverd-inkludering",
      no_NO: "/mangfold-likeverd-inkludering",
      "sv-SE": "/mangfald-jamlikhet-inkludering",
      sv_SE: "/mangfald-jamlikhet-inkludering"
    },
    error: {
      en: "/error",
      "pt-BR": "/error",
      pt_BR: "/error",
      "da-DK": "/error",
      da_DK: "/error",
      "de-DE": "/error",
      de_DE: "/error",
      "es-ES": "/error",
      es_ES: "/error",
      "fr-FR": "/error",
      fr_FR: "/error",
      "it-IT": "/error",
      it_IT: "/error",
      "ja-JP": "/error",
      ja_JP: "/error",
      "no-NO": "/error",
      no_NO: "/error",
      "sv-SE": "/error",
      sv_SE: "/error"
    },
    "faqs/index": {
      en: "/faqs",
      "pt-BR": "/perguntas-frequentes",
      pt_BR: "/perguntas-frequentes",
      "da-DK": "/ofte-stillede-sporgsmal",
      da_DK: "/ofte-stillede-sporgsmal",
      "de-DE": "/faq",
      de_DE: "/faq",
      "es-ES": "/preguntas-frecuentes",
      es_ES: "/preguntas-frecuentes",
      "fr-FR": "/faq",
      fr_FR: "/faq",
      "it-IT": "/faq",
      it_IT: "/faq",
      "ja-JP": "/faqs",
      ja_JP: "/faqs",
      "no-NO": "/ofte-stilte-sporsmal",
      no_NO: "/ofte-stilte-sporsmal",
      "sv-SE": "/vanliga-fragor",
      sv_SE: "/vanliga-fragor"
    },
    "formula/index": {
      en: "/formula",
      "pt-BR": "/formula",
      pt_BR: "/formula",
      "da-DK": "/formel",
      da_DK: "/formel",
      "de-DE": "/die-adyen-formel",
      de_DE: "/die-adyen-formel",
      "es-ES": "/formula",
      es_ES: "/formula",
      "fr-FR": "/formula",
      fr_FR: "/formula",
      "it-IT": "/formula",
      it_IT: "/formula",
      "ja-JP": "/formula",
      ja_JP: "/formula",
      "no-NO": "/formel",
      no_NO: "/formel",
      "sv-SE": "/formeln",
      sv_SE: "/formeln"
    },
    index: {
      en: "/",
      "pt-BR": "/",
      pt_BR: "/",
      "da-DK": "/",
      da_DK: "/",
      "de-DE": "/",
      de_DE: "/",
      "es-ES": "/",
      es_ES: "/",
      "fr-FR": "/",
      fr_FR: "/",
      "it-IT": "/",
      it_IT: "/",
      "ja-JP": "/",
      ja_JP: "/",
      "no-NO": "/",
      no_NO: "/",
      "sv-SE": "/",
      sv_SE: "/"
    },
    "locations/[slug]": {
      en: "/locations/[slug]",
      "pt-BR": "/onde-estamos/[slug]",
      pt_BR: "/onde-estamos/[slug]",
      "da-DK": "/placeringer/[slug]",
      da_DK: "/placeringer/[slug]",
      "de-DE": "/standorte/[slug]",
      de_DE: "/standorte/[slug]",
      "es-ES": "/ubicaciones/[slug]",
      es_ES: "/ubicaciones/[slug]",
      "fr-FR": "/bureaux/[slug]",
      fr_FR: "/bureaux/[slug]",
      "it-IT": "/localita/[slug]",
      it_IT: "/localita/[slug]",
      "ja-JP": "/locations/[slug]",
      ja_JP: "/locations/[slug]",
      "no-NO": "/steder/[slug]",
      no_NO: "/steder/[slug]",
      "sv-SE": "/platser/[slug]",
      sv_SE: "/platser/[slug]"
    },
    "locations/index": {
      en: "/locations",
      "pt-BR": "/onde-estamos",
      pt_BR: "/onde-estamos",
      "da-DK": "/placeringer",
      da_DK: "/placeringer",
      "de-DE": "/standorte",
      de_DE: "/standorte",
      "es-ES": "/ubicaciones",
      es_ES: "/ubicaciones",
      "fr-FR": "/bureaux",
      fr_FR: "/bureaux",
      "it-IT": "/localita",
      it_IT: "/localita",
      "ja-JP": "/locations",
      ja_JP: "/locations",
      "no-NO": "/steder",
      no_NO: "/steder",
      "sv-SE": "/platser",
      sv_SE: "/platser"
    },
    "vacancies/[slug]": {
      en: "/vacancies/[slug]",
      "pt-BR": "/vacancies/[slug]",
      pt_BR: "/vacancies/[slug]",
      "da-DK": "/vacancies/[slug]",
      da_DK: "/vacancies/[slug]",
      "de-DE": "/vacancies/[slug]",
      de_DE: "/vacancies/[slug]",
      "es-ES": "/vacancies/[slug]",
      es_ES: "/vacancies/[slug]",
      "fr-FR": "/vacancies/[slug]",
      fr_FR: "/vacancies/[slug]",
      "it-IT": "/vacancies/[slug]",
      it_IT: "/vacancies/[slug]",
      "ja-JP": "/vacancies/[slug]",
      ja_JP: "/vacancies/[slug]",
      "no-NO": "/vacancies/[slug]",
      no_NO: "/vacancies/[slug]",
      "sv-SE": "/vacancies/[slug]",
      sv_SE: "/vacancies/[slug]"
    },
    "vacancies/index": {
      en: "/vacancies",
      "pt-BR": "/vacancies",
      pt_BR: "/vacancies",
      "da-DK": "/vacancies",
      da_DK: "/vacancies",
      "de-DE": "/vacancies",
      de_DE: "/vacancies",
      "es-ES": "/vacancies",
      es_ES: "/vacancies",
      "fr-FR": "/vacancies",
      fr_FR: "/vacancies",
      "it-IT": "/vacancies",
      it_IT: "/vacancies",
      "ja-JP": "/vacancies",
      ja_JP: "/vacancies",
      "no-NO": "/vacancies",
      no_NO: "/vacancies",
      "sv-SE": "/vacancies",
      sv_SE: "/vacancies"
    }
  },
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    name: "Global",
    language: "en",
    files: []
  },
  {
    code: "pt_BR",
    name: "Brasil",
    language: "pt-BR",
    files: []
  },
  {
    code: "da_DK",
    name: "Danmark",
    language: "da-DK",
    files: []
  },
  {
    code: "de_DE",
    name: "Deutschland",
    language: "de-DE",
    files: []
  },
  {
    code: "es_ES",
    name: "España",
    language: "es-ES",
    files: []
  },
  {
    code: "fr_FR",
    name: "France",
    language: "fr-FR",
    files: []
  },
  {
    code: "it_IT",
    name: "Italia",
    language: "it-IT",
    files: []
  },
  {
    code: "ja_JP",
    name: "日本",
    language: "ja-JP",
    files: []
  },
  {
    code: "no_NO",
    name: "Norge",
    language: "no-NO",
    files: []
  },
  {
    code: "sv_SE",
    name: "Sverige",
    language: "sv-SE",
    files: []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/