import payload_plugin_K629R2esWBjSAINQVKCTWyLt1kpEfI2gpHzIMMmaGnE from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_nJbkLYoq9al4DVGfrwGpNwdzon5eBsXzl0lCDbxxrUY from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_b_f811dTL_awBaZhYH08f8Db_bQMMlQYlpMPtoe7n7c from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_c9PFreqQNE_iGlx42BDS6BQS1hFKBIyCGsfBIJAvFH8 from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MkTG6VB9SgkUEHQhGTb1KC9AWSg_z1XpfADmrzYPG3o from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_rJRQK0TMBboZcUDIqsAuPu934XiL5qAAxDdxrZ__0r8 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GLGsFbulfUN8qOrjV1Oe2ISND_S49zh7HE9A1O6ja34 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Io86IcoxvWG2W3X04r0dtuH7OES9LYBjzZEgn4l2OBw from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8M_OwQXeaqnJAr8q0xH4PT1WYzJA1AnH7MVH40ql_G0 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import switch_locale_path_ssr_Q4QG_JWrFkldguQIG03zqcO_kK_4AUpve2dv3MvQ_1I from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_XKVBybqs_BXvMN1URnuJp0XGMyBFmJaJQkYoeau32jM from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_19v21r6K1zdMR19WY1buFv7wSXagWynDr1PPElFzQrU from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_vue3_OXQZF4SIGwKvC2ltUuLC9__N0H6iOIQ_1DX3zcol9_c from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/build/repo/website/careers/.nuxt/components.plugin.mjs";
import prefetch_client_usSU70_PwDtu0IWV9YOSUBm27VIyY0wu21VUrO_zP1c from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_VthtKQTTO_r5zTWLTIG0cXah4Ozf82PEf4CRd_bf618 from "/opt/build/repo/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import gsapPlugin_fQJzKad1g_0brIZecwc2UCp0l5TQU9rrNzzf4RmL62w from "/opt/build/repo/website/careers/.nuxt/gsapPlugin.mjs";
import i18n_client_p75rPXNu1SoQk2X2XqRK4S6faw4QQBxhxyLHU_CV7qU from "/opt/build/repo/website/core/plugins/i18n.client.ts";
import ssg_detect_JQT9b_sVERYDW__eN0wuiqDH6nql0nVptMbJ3IvFy8E from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_K629R2esWBjSAINQVKCTWyLt1kpEfI2gpHzIMMmaGnE,
  revive_payload_client_nJbkLYoq9al4DVGfrwGpNwdzon5eBsXzl0lCDbxxrUY,
  unhead_b_f811dTL_awBaZhYH08f8Db_bQMMlQYlpMPtoe7n7c,
  router_c9PFreqQNE_iGlx42BDS6BQS1hFKBIyCGsfBIJAvFH8,
  _0_siteConfig_MkTG6VB9SgkUEHQhGTb1KC9AWSg_z1XpfADmrzYPG3o,
  payload_client_rJRQK0TMBboZcUDIqsAuPu934XiL5qAAxDdxrZ__0r8,
  navigation_repaint_client_GLGsFbulfUN8qOrjV1Oe2ISND_S49zh7HE9A1O6ja34,
  check_outdated_build_client_Io86IcoxvWG2W3X04r0dtuH7OES9LYBjzZEgn4l2OBw,
  chunk_reload_client_8M_OwQXeaqnJAr8q0xH4PT1WYzJA1AnH7MVH40ql_G0,
  switch_locale_path_ssr_Q4QG_JWrFkldguQIG03zqcO_kK_4AUpve2dv3MvQ_1I,
  route_locale_detect_XKVBybqs_BXvMN1URnuJp0XGMyBFmJaJQkYoeau32jM,
  i18n_19v21r6K1zdMR19WY1buFv7wSXagWynDr1PPElFzQrU,
  plugin_vue3_OXQZF4SIGwKvC2ltUuLC9__N0H6iOIQ_1DX3zcol9_c,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_usSU70_PwDtu0IWV9YOSUBm27VIyY0wu21VUrO_zP1c,
  plugin_VthtKQTTO_r5zTWLTIG0cXah4Ozf82PEf4CRd_bf618,
  gsapPlugin_fQJzKad1g_0brIZecwc2UCp0l5TQU9rrNzzf4RmL62w,
  i18n_client_p75rPXNu1SoQk2X2XqRK4S6faw4QQBxhxyLHU_CV7qU,
  ssg_detect_JQT9b_sVERYDW__eN0wuiqDH6nql0nVptMbJ3IvFy8E
]